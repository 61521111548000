import { Component, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import BadgesMixin from '@/modules/level-two/views/event/modules/badges/mixins/badges.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { mapState } from 'vuex'
import GtrSvgBuilder from '@/modules/common/components/ui-core/gtr-svg-builder/gtr-svg-builder.vue'
import { RegistrationType } from '@/modules/level-two/models/badges.model'

@Component({
  name: 'GtrCertificatesView',
  components: {
    'gtr-svg-builder': GtrSvgBuilder
  },
  computed: {
    ...mapState('certificates', ['certificates']),
    ...mapState('event', ['event_stats', 'registration_type_counts']),
    ...mapState('formbuilder', ['eventFields']),
    ...mapState('option', ['option_groups'])
  }
})
export default class GtrCertificatesView extends mixins(GtrSuper, BadgesMixin) {
  certificates!: Array<Record<string, any>>;

  registrationTypes!: Array<RegistrationType>;

  event_stats!: Record<string, any>;

  registration_type_counts!: Array<Record<string, any>>;

  eventFields!: Array<Record<string, any>>;

  option_groups!: Array<Record<string, any>>;

  data () {
    return {
      loading: false,
      submitting: false,
      showSvgBuilderModal: false,
      showCertificateFormModal: false,
      certificateFormModalType: null,
      svgSize: null,
      certificateObj: null,
      certificate: {
        name: null,
        svg: null,
        certificate_properties: {
          description: null,
          size: null,
          user_name: null
        },
        registration_type: null,
        active: true
      },
      previewModal: {
        svg: '',
        dataPreview: '',
        title: '',
        regType: '',
        isOpen: false
      },
      previewData: false,
      additionalCustomFields: [
        {
          label: 'Credits',
          value: 'credits'
        },
        {
          label: 'Alt Credits',
          value: 'alt_credits'
        }
      ]
    }
  }

  async mounted () {
    try {
      this.$data.loading = true
      this.$data.emptyCertificate = this.$data.certificate // store default values
      await this.fetchCertificates()
      await this.fetchEventStats()
      await this.fetchRegistrationTypeCounts()
      await this.fetchEventFields()
      await this.fetchOptionGroups()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async fetchCertificates () {
    await this.$store.dispatch('certificates/fetchCertificates', { event_uuid: this.$route.params.event_uuid })
  }

  async fetchEventStats () {
    await this.$store.dispatch('event/getEventStats', this.$route.params.event_uuid)
  }

  async fetchRegistrationTypeCounts () {
    await this.$store.dispatch('event/getRegistrationTypeCounts', { event_uuid: this.$route.params.event_uuid })
  }

  async fetchEventFields () {
    await this.$store.dispatch('formbuilder/getEventFields', { event_uuid: this.$route.params.event_uuid })
  }

  async fetchOptionGroups () {
    await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
  }

  handleCreateNew () {
    if (!this.activeCertificate) {
      this.$data.svgSize = '11" x 8.5"'
      this.$data.showSvgBuilderModal = true
    } else {
      Container.get(Notification).error('Please archive the active certificate before creating a new one.')
    }
  }

  handleEditDetails (index: number) {
    this.handleOpenCertificateFormModal('edit', this.certificates[index])
  }

  handleOpenInEditor (index: number) {
    this.$data.svgSize = this.certificates[index].certificate_properties?.size
    this.$data.certificateObj = this.certificates[index]
    this.$data.showSvgBuilderModal = true
  }

  handleDuplicate (index: number) {
    this.handleOpenCertificateFormModal('duplicate', this.certificates[index])
  }

  async handleArchive (index: number) {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        certificate_uuid: this.certificates[index].uuid,
        data: {
          active: 0
        }
      }
      await this.$store.dispatch('certificates/updateCertificate', payload)
      await this.fetchCertificates()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async handleRestore (index: number) {
    if (!this.activeCertificate) {
      try {
        this.$data.loading = true
        const payload = {
          event_uuid: this.$route.params.event_uuid,
          certificate_uuid: this.certificates[index].uuid,
          data: {
            active: 1
          }
        }
        await this.$store.dispatch('certificates/updateCertificate', payload)
        await this.fetchCertificates()
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    } else {
      Container.get(Notification).error('Please archive the active certificate before restoring.')
    }
  }

  handleOpenCertificateFormModal (formType: string, certificate: Record<string, any>) {
    this.$data.certificateFormModalType = formType
    this.$data.certificate = certificate
    if (formType === 'duplicate') {
      delete this.$data.certificate.uuid
      delete this.$data.certificate.created_at
      delete this.$data.certificate.updated_at
      delete this.$data.certificate.deleted_at
      this.$data.certificate.active = 0 // make duplicate inactive
    }
    this.$data.showCertificateFormModal = true
  }

  handleCloseSvgBuilderModal () {
    this.$data.svgSize = null
    this.$data.certificateObj = null
    this.$data.certificate = this.$data.emptyCertificate
    this.$data.showSvgBuilderModal = false
  }

  handleCloseCertificateFormModal () {
    this.$data.showCertificateFormModal = false
    this.$data.certificateFormModalType = null
    this.$data.certificate = this.$data.emptyCertificate // restore default values
  }

  async submit () {
    try {
      this.$data.submitting = true
      if (this.$data.certificateFormModalType === 'edit') {
        await this.editCertificateDetails()
      }
      if (this.$data.certificateFormModalType === 'duplicate') {
        await this.duplicateCertificate()
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      if (this.$data.certificateFormModalType === 'duplicate') {
        Container.get(Notification).success('Certificate successfully duplicated.')
      }
      this.$data.submitting = false
    }
  }

  async editCertificateDetails () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        certificate_uuid: this.$data.certificate.uuid,
        data: this.$data.certificate
      }
      await this.$store.dispatch('certificates/updateCertificate', payload)
      await this.fetchCertificates()
      Container.get(Notification).success('Certificate successfully saved.')
      this.handleCloseCertificateFormModal()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async duplicateCertificate () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        data: this.$data.certificate
      }
      await this.$store.dispatch('certificates/createCertificate', payload)
      await this.fetchCertificates()
      Container.get(Notification).success('Certificate successfully saved.')
      this.handleCloseCertificateFormModal()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  handleOpenPreviewModal (index: number) {
    this.$data.previewModal.svg = this.certificates[index].svg
    this.$data.previewModal.title = this.certificates[index].name
    this.$data.previewModal.regType = this.certificates[index].registration_type
    this.$data.previewModal.isOpen = true
  }

  handleClosePreviewModal () {
    this.$data.previewModal.isOpen = false
    setTimeout(() => {
      this.$data.previewModal.svg = ''
      this.$data.previewModal.title = ''
      this.$data.previewModal.regType = ''
      this.$data.previewModal.dataPreview = ''
      this.$data.previewData = false
    }, 300)
  }

  async toggleDataPreview (e: MouseEvent): Promise<void> {
    if (!this.$data.previewModal.dataPreview) {
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        reg_type: this.$data.previewModal.regType
      }
      const rando = await this.$store.dispatch('attendee/fetchRandomAttendee', payload)
      if (!rando || !rando.participant_data || !rando.selected_options) {
        this.$data.previewModal.dataPreview = ''
      } else {
        this.$data.previewModal.dataPreview = this.buildDataPreview(this.$data.previewModal.svg, rando.participant_data, rando.selected_options)
      }
    }
    this.$data.previewData = !this.$data.previewData
  }

  // COMPUTED

  get registrationTypesAndCertificates (): RegistrationType[] {
    const regTypesAndCertificates = {}

    if (Array.isArray(this.certificates)) {
      this.certificates.forEach((certificate) => {
        if (certificate.registration_type) {
          regTypesAndCertificates[certificate.registration_type] = certificate.uuid
        }
      })
    }

    // Add certificate UUIDs to reg types if they have been assigned.
    if (Array.isArray(this.registrationTypes)) {
      return this.registrationTypes.reduce(
        function (regTypes: RegistrationType[], regTypeObj: RegistrationType): RegistrationType[] {
          const regType: RegistrationType = {
            name: regTypeObj.name,
            uuid: regTypeObj.uuid,
            visible: regTypeObj.visible,
            certificate_uuid: regTypesAndCertificates[regTypeObj.uuid] || null
          }
          regTypes.push(regType)
          return regTypes
        }, []
      )
    }

    return []
  }

  get activeCertificate () {
    const filtered = this.certificates.filter(certificate => certificate.active)
    return filtered[0]
  }

  get certificateFormModalTitle () {
    if (this.$data.certificateFormModalType === 'edit') {
      return 'Edit Certificate'
    }
    if (this.$data.certificateFormModalType === 'duplicate') {
      return 'Duplicate Certificate'
    }
  }
}
